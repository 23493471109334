import React from "react"

import { Link } from "gatsby"

import Layout from "../parts/layout"
import Seo from "../parts/seo"

const ToolsPage = () => {
  return (
    <Layout>
      <Seo title="Tools" />
      <div className="row">
        <h1 className="page-title">Public Stack Tools</h1>

        <div className="items">

          <div className="item box flow">
            <Link to={`/cards/`}>
              <img className="responsive-image" src="/public-stack-reflection-cards.jpg" alt="Photo of the Public Stack Reflection Cards deck showing the fout categories Foundation, Design Process, Technology and People & Planet" />
            </Link>
            <div className="text">
              <h2><Link to={`/cards/`}>Public Stack Reflection Cards</Link></h2>
              <p>The Public Stack Reflection Cards prompt reflection about your technology, its development, and the values it promotes. They are best used together with a team in a live setting.</p>
            </div>
            <div className="buttons">
              <Link className="button" to={`/cards/`}>Learn More</Link>
            </div>
          </div>

          <div className="item box flow">
            <Link to={`/responsible-business-model-canvas/`}>
              <img className="responsive-image" src="/responsible-business-model-canvas.png" alt="" />
            </Link>
            <div className="text">
              <h2><Link to={`/responsible-business-model-canvas/`}>Responsible Business Model Canvas</Link></h2>
              <p>The Responsible Business Model Canvas and the accompanying methods can help you to give shape to a value-based business model that form the foundation of your digital public spaces initiative.</p>
            </div>
            <div className="buttons">
              <Link className="button" to={`/responsible-business-model-canvas/`}>Learn More</Link>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default ToolsPage
